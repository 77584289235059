// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-backup-form-js": () => import("./../../../src/pages/backup-form.js" /* webpackChunkName: "component---src-pages-backup-form-js" */),
  "component---src-pages-cara-order-js": () => import("./../../../src/pages/cara-order.js" /* webpackChunkName: "component---src-pages-cara-order-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-hubungi-kami-js": () => import("./../../../src/pages/hubungi-kami.js" /* webpackChunkName: "component---src-pages-hubungi-kami-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-klien-kami-js": () => import("./../../../src/pages/klien-kami.js" /* webpackChunkName: "component---src-pages-klien-kami-js" */),
  "component---src-pages-layanan-gratis-js": () => import("./../../../src/pages/layanan-gratis.js" /* webpackChunkName: "component---src-pages-layanan-gratis-js" */),
  "component---src-pages-portofolio-js": () => import("./../../../src/pages/portofolio.js" /* webpackChunkName: "component---src-pages-portofolio-js" */),
  "component---src-pages-standar-ukuran-js": () => import("./../../../src/pages/standar-ukuran.js" /* webpackChunkName: "component---src-pages-standar-ukuran-js" */)
}

